import React from 'react';
import Layout from 'components/Layout';
import bulb from "../assets/images/scrumboard/bulb.svg";
import people from "../assets/images/scrumboard/people-giving-five.svg";
import dashboard from "../assets/images/scrumboard/dashboard.svg";
import pencil from "../assets/images/scrumboard/pencil.svg";
import arrowDashed from "../assets/images/arrow-dashed.svg";
import line from "../assets/images/scrumboard/line1.svg";
import man from "../assets/images/scrumboard/man.svg";
import dashboard2 from "../assets/images/scrumboard/dashboard-scrumboard.svg";
import checkboard from "../assets/images/scrumboard/checkboard-img.svg";
import clock from "../assets/images/scrumboard/clock-img.svg";
import bigArrow from "../assets/images/scrumboard/big-arrow-up.svg";
import zigzagLine from "../assets/images/scrumboard/zigzag-line.svg";
import arrowDefault from "../assets/images/scrumboard/arrow-default.svg";
import arrowUp from "../assets/images/scrumboard/arrow5.svg";
import arrow from "../assets/images/scrumboard/arrow.svg";
import lineAdvice1 from "../assets/images/scrumboard/line-advice1.svg";
import lineAdvice2 from "../assets/images/line2.svg";
import lineAdvice3 from "../assets/images/scrumboard/line-advice3.svg";
import calendar from "../assets/images/scrumboard/calendar.svg";
import releaseTable from "../assets/images/scrumboard/release-table.svg";
import manTool from "../assets/images/scrumboard/man-tool.svg";
import arrowShort from "../assets/images/scrumboard/arrow-short.svg";
import notes from "../assets/images/scrumboard/notes.svg";
import manCard from "../assets/images/scrumboard/man-card.svg";
import backlogBoard from "../assets/images/scrumboard/backlog.svg";
import ticketsCol from "../assets/images/scrumboard/tickets-column.svg";
import arrow2 from "../assets/images/scrumboard/arrow2.svg";
import arrow3 from "../assets/images/scrumboard/arrow3.svg";
import arrow4 from "../assets/images/scrumboard/arrow4.svg";
import checklistBoard from '../assets/images/scrumboard/checklist-board.svg';
import arrowMiddle from "../assets/images/scrumboard/arrow-middle.svg";
import scoresBoard from "../assets/images/scrumboard/scores-board.svg";
import stars from "../assets/images/scrumboard/stars.svg";
import developer from "../assets/images/scrumboard/developer.svg";
import line3 from "../assets/images/scrumboard/line3.svg";
import line2Longer from "../assets/images/scrumboard/line2b.svg";
import card from "../assets/images/scrumboard/card.svg";
import ticket from "../assets/images/scrumboard/ticket.svg";
import lineTopLeft from "../assets/images/scrumboard/line-top-left.svg";
import lineTopRight from "../assets/images/scrumboard/line-top-right.svg";
import lineRight from "../assets/images/scrumboard/line-right.svg";
import lineBottomRight from "../assets/images/scrumboard/line-bottom-right.svg";
import lineBottomMiddle from "../assets/images/line-bottom-middle.svg";
import lineBottomLeft from "../assets/images/scrumboard/line-bottom-left.svg";
import lineLeft from "../assets/images/scrumboard/line-left.svg";
import redTicket from "../assets/images/scrumboard/red-ticket.svg";
import arrowMain from "../assets/images/scrumboard/arrow-main.svg";
import lineDown2 from "../assets/images/scrumboard/line-down2.svg";
import cloud from "../assets/images/scrumboard/cloud.svg";
import {defineMessages, useIntl} from 'react-intl';
import Img from "../components/Img";

const intro = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Scrumboard'
    },
    protipHeader: {
        id: 'Header.Text',
        defaultMessage: 'User-friendly'
    },
    protipText: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship offers user-friendly functionalities for the entire development team.'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Without limits'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'While some other softwares limits you to just a sprint board view, Scrumship lets you quickly know where things stand'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'Created with passion'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship is created with passion for high-performance teams to save time and reduce errors by managing work from a variety of tools in one centralized place.'
    },
    bulb: {
        id: 'Alt.Text',
        defaultMessage: 'yellow bulb'
    },
    people: {
        id: 'Alt.Text',
        defaultMessage: 'happy people'
    },
    dashboard: {
        id: 'Alt.Text',
        defaultMessage: 'scrumboard - dashboard'
    },
    pencil: {
        id: 'Alt.Text',
        defaultMessage: 'pink pencil'
    },
});

const sprintboard = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Sprint Board'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Manage Work'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Manage all your team\'s work in one place and improve your\n' + 'performance.'
    },
    advice1Header: {
        id: 'Header.Text',
        defaultMessage: 'Bugs and adjustments'
    },
    advice1Text: {
        id: 'Content.Text',
        defaultMessage: 'Tasks stemming from problems within the software are visible on the board.'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'Organize work'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'It can be used to schedule how features are rolled out to your customers, or as a way to organize work that has been completed for the project.'
    },
    protip3Header: {
        id: 'Header.Text',
        defaultMessage: 'Easy lunch new versions'
    },
    protip3Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship ensures that bugs and adjustments needed are prioritized. Once they are captured and assigned, team member is notified. With Releases on your sprint board you can quickly create and launch new versions of your software.'
    },
    advice2Header: {
        id: 'Header.Text',
        defaultMessage: 'Releases'
    },
    advice2Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship gives you a tool to plan and manage your Releases from Sprint Board.'
    },
    advice3Header: {
        id: 'Header.Text',
        defaultMessage: 'Create and manage your sprintboard'
    },
    advice3Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship makes certain all tasks in a sprint have a path to another status so your\n' + 'team can continue to move work forward.'
    },
    dashboard: {
        id: 'Alt.Text',
        defaultMessage: 'sprint board in Scrumship task management app'
    },
});

const releases = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Releases'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Easily check'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'You can easily check what new improvements, features and bug fixes were launched in each release.'
    },
    advice1Header: {
        id: 'Header.Text',
        defaultMessage: 'Releases calendar'
    },
    advice1Text: {
        id: 'Content.Text',
        defaultMessage: 'The release calendar is a tool which provides a fast insight into when the team released a software changes.'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'Generate releases notes'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship generates release notes providing information on the features and improvements that you can email with one click.'
    },
    protip3Header: {
        id: 'Header.Text',
        defaultMessage: 'Manage release cycle'
    },
    protip3Text: {
        id: 'Content.Text',
        defaultMessage: 'You can plan release dates for each status. Once you move to Prod or Beta you can answer some release checklist questions, to make sure it all went well with no issues. '
    },
    calendar: {
        id: 'Alt.Text',
        defaultMessage: 'releases calendar in Scrumship management software'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'sprint board in Scrumship task management software'
    },
});

const backlog = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Backlog'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Easy and quick planning'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Backlog management in Scrumship enables easy and quick planning both in the backlog, in the current sprint and the next sprint. The drag and drop option allows you to quickly move tasks from the current sprint to the next one, and also add tasks from the backlog to the current sprint. With our timeline you can compare the amount of work done, you can define a sprint goal, check sprint duration and keep track of your progress with ease.'
    },
    advice1Header: {
        id: 'Header.Text',
        defaultMessage: 'Timeline'
    },
    advice1Text: {
        id: 'Content.Text',
        defaultMessage: 'We give you a tool to track the progress of sprints throughout the year.'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'Manage issues from one place'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'Sprint planning in Scrumship was never so easy. You do not need to navigate away from your board. You have a detailed view of the currently selected task, you can also add new, assign, estimate, and categorize issues. From one place you can manage issues that you’re currently working on, issues which you plan to work on in the near future and issues you want to work on in a month or two.'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'backlog board in Scrumship task management software'
    },
});

const checklist = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Checklist'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Outline specific tasks'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship gives you a Checklists feature that outline specific tasks that team members need to complete at different stages of the project so that everyone involved knows what needs to happen next. Your team members will not forget a step or process during the app development.'
    },
    advice1Header: {
        id: 'Header.Text',
        defaultMessage: 'Manage task checklist'
    },
    advice1Text: {
        id: 'Content.Text',
        defaultMessage: 'This tool brings a clear outline to your tasks letting you instruct an assignee on how to complete the task.'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'checklist board in Scrumship management software'
    },
});

const scores = defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Scores'
    },
    protip1Header: {
        id: 'Header.Text',
        defaultMessage: 'Employee performance evaluation'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Keeping a running scorecard can be a powerful tool both in encouraging progress and making sound business decisions regarding disciplinary measures, promotions, and merit or bonus payouts.'
    },
    protip2Header: {
        id: 'Header.Text',
        defaultMessage: 'Real-time feedback'
    },
    protip2Text: {
        id: 'Content.Text',
        defaultMessage: 'Scrumship gives you a tool where team members  have the possibility to grade the task performance of their colleagues. They award scores for Implementation, Code review and Testing skills. It is important for employees to receive real-time feedback and immediate recognition.'
    },
    board: {
        id: 'Alt.Text',
        defaultMessage: 'scores board in Scrumship '
    },
});

const ticketsIntro= defineMessages({
    header: {
        id: 'Header.Text',
        defaultMessage: 'Tickets'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Easily assign tasks, get notifications on what your team are working on in real-time, and communicate in the context of every project and task. Tags in Scrumship are completely customizable. This helps when you need to group, view, or manage related tasks.'
    },
});

const ticketsMain= defineMessages({
    adviceTopLeftHeader: {
        id: 'Header.Text',
        defaultMessage: 'Tags'
    },
    adviceTopLeftText: {
        id: 'Content.Text',
        defaultMessage: 'You can apply tags to tasks in your workspace to identify and quickly categorize them.'
    },
    adviceTopRightHeader: {
        id: 'Header.Text',
        defaultMessage: 'Time in status'
    },
    adviceTopRightText: {
        id: 'Content.Text',
        defaultMessage: 'On the ticket you will also find information about how long the ticket has been pending in a particular status.'
    },
    adviceRightHeader: {
        id: 'Header.Text',
        defaultMessage: 'Priorities'
    },
    adviceRightText: {
        id: 'Content.Text',
        defaultMessage: 'Users can classify the prioritization of the given task.'
    },
    adviceBottomRightHeader: {
        id: 'Header.Text',
        defaultMessage: 'Estimated time of arrival'
    },
    adviceBottomRightText: {
        id: 'Content.Text',
        defaultMessage: 'Informs the team when the task is expected to be delivered. '
    },
    adviceBottomMiddleHeader: {
        id: 'Header.Text',
        defaultMessage: 'Progress'
    },
    adviceBottomMiddleText: {
        id: 'Content.Text',
        defaultMessage: 'We give an overview to task progress. It shows percentage of logged hours versus estimated time of the task.'
    },
    adviceBottomLeftHeader: {
        id: 'Header.Text',
        defaultMessage: 'Current status'
    },
    adviceBottomLeftText: {
        id: 'Content.Text',
        defaultMessage: 'We can easily see on the timeline, current point in time.'
    },
    adviceLeftHeader: {
        id: 'Header.Text',
        defaultMessage: 'Work state date'
    },
    adviceLeftText: {
        id: 'Content.Text',
        defaultMessage: 'When we first move the ticket to in progress.'
    },
    protip1Header: {
        id: 'Content.Text',
        defaultMessage: 'Smart visualization'
    },
    protip1Text: {
        id: 'Content.Text',
        defaultMessage: 'Tickets change their color when the task is for too long in one status and not being taken care of. We can define the bounds of how long the task can be kept in a given status eg. in ready for code review before it got reviewed.'
    },
    ticket: {
        id: 'Alt.Text',
        defaultMessage: 'ticket in app management - Scrumship'
    },
    redTicket: {
        id: 'Alt.Text',
        defaultMessage: 'ticket after deadline in Scrumship '
    }
});

const globalAlts =defineMessages({
    junior: {
        id: 'Alt.Text',
        defaultMessage: 'junior developer'
    },
    clock: {
        id: 'Alt.Text',
        defaultMessage: 'clock'
    },
    todo: {
        id: 'Alt.Text',
        defaultMessage: 'todo list'
    },
    developer: {
        id: 'Alt.Text',
        defaultMessage: 'senior developer'
    },
    stars: {
        id: 'Alt.Text',
        defaultMessage: 'yellow stars'
    },
    card: {
        id: 'Alt.Text',
        defaultMessage: 'to do card'
    },
});

const Scrumboard = ({location}) => {
    const {formatMessage} = useIntl();
    return (
        <Layout pageName="scrumboard-subpage" location={location}>
            <section className='intro'>
                <div className='container'>
                    <div className='section-one'>
                        <div className='heart'>
                            <div className='bg-container'>
                                <div className='content-container full-width'>
                                    <div className='title title-no-line'><h3>{formatMessage(intro.protipHeader)}</h3>
                                    </div>
                                    <p className='protip-black'>{formatMessage(intro.protipText)}</p>
                                </div>
                            </div>
                        </div>
                        <div className='protip1'>
                            <div className='content-container full-width text-left'>
                                <div className='title title-no-line'><h3>{formatMessage(intro.protip1Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(intro.protip1Text)}</p>
                            </div>
                            <div className='arrow-main'>
                                <img src={arrowMain} alt=""/>
                            </div>
                        </div>
                        <div className='bulb'>
                            <div className='image-container full-width'>
                                <Img src={bulb} alt={formatMessage(intro.bulb)}/>
                            </div>
                        </div>
                        <div className='people'>
                            <div className='image-container full-width'>
                                <Img src={people} alt={formatMessage(intro.people)}/>
                            </div>
                        </div>
                        <div className='dashboard'>
                            <div className='image-container full-width'>
                                <Img src={dashboard} alt={formatMessage(intro.dashboard)}/>
                            </div>
                        </div>
                        <div className='protip2'>
                            <div className='content-container full-width text-left'>
                                <div className='title title-no-line'><h3>{formatMessage(intro.protip2Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(intro.protip2Text)}</p>
                            </div>
                            <div className='image-container full-width'>
                                <Img src={pencil} alt={formatMessage(intro.pencil)}/>
                            </div>
                        </div>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(intro.header)}</h1>
                        </div>
                        <div className='arrow-container'>
                            <Img src={arrowDashed} alt=""/>
                        </div>
                        <div className='line-container'>
                            <Img src={line} alt=""/>
                        </div>
                        <div className='cloud'>
                            <div className='image-container full-width'>
                                <Img src={cloud} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='sprint-board'>
                <div className='container'>
                    <div className='section-two'>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(sprintboard.header)}</h1>
                        </div>
                        <div className='junior'>
                            <div className='image-container full-width'>
                                <Img src={man} alt={formatMessage(globalAlts.junior)}/>
                            </div>
                        </div>
                        <div className='protip1'>
                            <div className='content-container full-width text-left'>
                                <div className='title'><h3>{formatMessage(sprintboard.protip1Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(sprintboard.protip1Text)}</p>
                            </div>
                        </div>
                        <div className='advice-container advice1'>
                            <div className='advice-body'>
                                <h4>{formatMessage(sprintboard.advice1Header)}</h4>
                                <p>{formatMessage(sprintboard.advice1Text)}</p>
                            </div>
                        </div>
                        <div className='dashboard'>
                            <div className='image-container full-width'>
                                <Img src={dashboard2} alt={formatMessage(sprintboard.dashboard)}/>
                            </div>
                        </div>
                        <div className='protip2'>
                            <div className='content-container full-width text-left'>
                                <div className='title'><h3>{formatMessage(sprintboard.protip2Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(sprintboard.protip2Text)}</p>
                            </div>
                            <div className='image-container full-width mt-xs'>
                                <Img src={zigzagLine} alt=""/>
                            </div>
                        </div>
                        <div className='protip3'>
                            <div className='wrapper'>
                                <div className='image-container'>
                                    <Img src={checkboard} alt={formatMessage(globalAlts.todo)}/>
                                </div>
                                <div className='content-container full-width text-left'>
                                    <div className='title'><h3>{formatMessage(sprintboard.protip3Header)}</h3></div>
                                    <p className='protip-black'>{formatMessage(sprintboard.protip3Text)}</p>
                                </div>
                            </div>
                        </div>
                        <div className='advice-container advice2'>
                            <div className='advice-body'>
                                <h4>{formatMessage(sprintboard.advice2Header)}</h4>
                                <p>{formatMessage(sprintboard.advice2Text)}</p>
                            </div>
                        </div>
                        <div className='advice-container advice3'>
                            <div className='advice-body'>
                                <h4>{formatMessage(sprintboard.advice3Header)}</h4>
                                <p>{formatMessage(sprintboard.advice3Text)}</p>
                            </div>
                        </div>
                        <div className='clock'>
                            <div className='image-container full-width'>
                                <Img src={clock} alt={formatMessage(globalAlts.clock)}/>
                            </div>
                        </div>
                        <div className='arrow-top'>
                            <Img src={arrow} alt=""/>
                        </div>
                        <div className='arrow-bottom'>
                            <Img src={arrowDefault} alt=""/>
                        </div>
                        <div className='arrow-left'>
                            <Img src={arrowUp} alt=""/>
                        </div>
                        <div className='line-advice1'>
                            <Img src={lineAdvice1} alt=""/>
                        </div>
                        <div className='line-advice2'>
                            <Img src={lineAdvice2} alt=""/>
                        </div>
                        <div className='line-advice3'>
                            <Img src={lineAdvice3} alt=""/>
                        </div>
                        <div className='arrow-left-bottom'>
                            <Img src={arrow2} alt=""/>
                        </div>
                        <div className='big-arrow-up'>
                            <Img src={bigArrow} alt=""/>
                        </div>
                    </div>
                </div>
            </section>
            <section className='releases'>
                <div className='container'>
                    <div className='section-three'>
                        <div className='protip1'>
                            <div className='content-container full-width text-left'>
                                <div className='title'><h3>{formatMessage(releases.protip1Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(releases.protip1Text)}</p>
                            </div>
                        </div>
                        <div className="col1">
                            <div className='calendar'>
                                <div className='image-container full-width'>
                                    <Img src={calendar} alt={formatMessage(releases.calendar)}/>
                                </div>
                            </div>
                            <div className='advice-container advice1'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(releases.advice1Header)}</h4>
                                    <p>{formatMessage(releases.advice1Text)}</p>
                                </div>
                            </div>
                            <div className='line-advice1'>
                                <Img src={lineAdvice2} alt=""/>
                            </div>
                        </div>
                        <div className='col2'>
                            <div className='rocket'>
                                <div className='junior'>
                                    <Img src={manTool} alt={formatMessage(globalAlts.junior)}/>
                                </div>
                                <div className='label'>
                                    <h1 className='text-decoration-label'>{formatMessage(releases.header)}</h1>
                                </div>
                                <div className='protip2'>
                                    <div className='content-container full-width text-left'>
                                        <div className='title'><h3>{formatMessage(releases.protip2Header)}</h3></div>
                                        <p className='protip-black'>{formatMessage(releases.protip2Text)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='release-table'>
                                <div className='image-container full-width'>
                                    <Img src={releaseTable} alt={formatMessage(releases.board)}/>
                                </div>
                            </div>
                            <div className='protip3'>
                                <div className='content-container full-width text-left'>
                                    <div className='title'><h3>{formatMessage(releases.protip3Header)}</h3></div>
                                    <p className='protip-black'>{formatMessage(releases.protip3Text)}</p>
                                </div>
                                <div className='image-container mt-xs'>
                                    <Img src={zigzagLine} alt=""/>
                                </div>
                            </div>
                            <div className='line-protip3'>
                                <Img src={arrowShort} alt=""/>
                            </div>
                        </div>
                        <div className='notes'>
                            <div className='image-container'>
                                <Img src={notes} alt=""/>
                            </div>
                        </div>
                        <div className='arrow3'>
                            <Img src={arrow3} alt=""/>
                        </div>
                    </div>
                </div>
            </section>
            <section className='backlog'>
                <div className='container'>
                    <div className='section-four'>
                        <div className='protip1'>
                            <div className='content-container text-left'>
                                <div className='title'><h3>{formatMessage(backlog.protip1Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(backlog.protip1Text)}</p>
                            </div>
                        </div>
                        <div className='advice-container advice1'>
                            <div className='advice-body'>
                                <h4>{formatMessage(backlog.advice1Header)}</h4>
                                <p>{formatMessage(backlog.advice1Text)}</p>
                            </div>
                        </div>
                        <div className='line-advice1'>
                            <Img src={lineDown2} alt=""/>
                        </div>
                        <div className='backlog'>
                            <div className='image-container full-width'>
                                <Img src={backlogBoard} alt={formatMessage(backlog.board)}/>
                            </div>
                        </div>
                        <div className='tickets'>
                            <div className='image-container full-width'>
                                <Img src={ticketsCol} alt={formatMessage(globalAlts.todo)}/>
                            </div>
                            <div className='arrow'>
                                <Img src={arrow4} alt=""/>
                            </div>
                        </div>
                        <div className='protip2'>
                            <div className='content-container full-width text-left'>
                                <div className='title'><h3>{formatMessage(backlog.protip2Header)}</h3></div>
                                <p className='protip-black'>{formatMessage(backlog.protip2Text)}</p>
                            </div>
                            <div className='image-container mt-xs'>
                                <Img src={zigzagLine} alt=""/>
                            </div>
                        </div>
                        <div className='junior'>
                            <div className='image-container full-width'>
                                <Img src={manCard} alt={formatMessage(globalAlts.junior)}/>
                            </div>
                        </div>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(backlog.header)}</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className='checklist'>
                <div className='container'>
                    <div className='section-five'>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(checklist.header)}</h1>
                        </div>
                        <div className='row1'>
                            <div className='image-container full-width'>
                                <Img src={checklistBoard} alt={formatMessage(checklist.board)}/>
                            </div>
                        </div>
                        <div className='row2'>
                            <div className='protip1'>
                                <div className='content-container full-width text-left'>
                                    <div className='title'><h3>{formatMessage(checklist.protip1Header)}</h3></div>
                                    <p className='protip-black'>{formatMessage(checklist.protip1Text)}</p>
                                </div>
                                <div className='image-container mt-xs'>
                                    <Img src={zigzagLine} alt=''/>
                                </div>
                            </div>
                            <div className='advice-container advice1'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(checklist.advice1Header)}</h4>
                                    <p>{formatMessage(checklist.advice1Text)}</p>
                                </div>
                            </div>
                            <div className='arrow'>
                                <Img src={arrowMiddle} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='scores'>
                <div className='container'>
                    <div className='section-six'>
                        <div className='label'>
                            <h1 className='text-decoration-label'>{formatMessage(scores.header)}</h1>
                        </div>
                        <div className='row1'>
                            <div className='image-container full-width'>
                                <Img src={scoresBoard} alt={formatMessage(scores.board)}/>
                            </div>
                        </div>
                        <div className='row2'>
                            <div className='protip1'>
                                <div className='content-container full-width text-left'>
                                    <div className='title'><h3>{formatMessage(scores.protip1Header)}</h3></div>
                                    <p className='protip-black'>{formatMessage(scores.protip1Text)}</p>
                                </div>
                            </div>
                            <div className='owner'>
                                <div className='image-container'>
                                    <Img src={developer} alt={formatMessage(globalAlts.developer)}/>
                                </div>
                                <div className='stars'>
                                    <div className='image-container'>
                                        <Img src={stars} alt={formatMessage(globalAlts.stars)}/>
                                    </div>
                                </div>
                            </div>
                            <div className='protip2'>
                                <div className='content-container full-width text-left'>
                                    <div className='title'><h3>{formatMessage(scores.protip2Header)}</h3></div>
                                    <p className='protip-black'>{formatMessage(scores.protip2Text)}</p>
                                </div>
                                <div className='image-container mt-xs'>
                                    <Img src={zigzagLine} alt=""/>
                                </div>
                            </div>
                            <div className='line-left'>
                                <Img src={line3} alt=""/>
                            </div>
                        </div>
                        <div className='line-up'>
                            <Img src={line2Longer} alt=""/>
                        </div>
                        <div className='card'>
                            <div className='image-container'>
                                <Img src={card} alt={formatMessage(globalAlts.card)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='tickets-intro'>
                <div className='container'>
                    <div className='section-seven-intro'>
                        <div className='row1'>
                            <div className='label'>
                                <h1 className='text-decoration-label'>{formatMessage(ticketsIntro.header)}</h1>
                            </div>
                            <div className='protip1'>
                                <div className='content-container text-center'>
                                    <p className='protip-black'>{formatMessage(ticketsIntro.protip1Text)}</p>
                                </div>
                                <div className='image-container mt-xs'>
                                    <Img src={zigzagLine} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='tickets-main'>
                <div className='container'>
                    <div className='section-seven-main'>
                        <div className='row1'>
                            <ul className='mobile-list list'>
                                <li><h4>{formatMessage(ticketsMain.adviceTopLeftHeader)}</h4> <p>- {formatMessage(ticketsMain.adviceTopLeftText)}</p></li>
                                <li><h4>{formatMessage(ticketsMain.adviceTopRightHeader)}</h4> <p>- {formatMessage(ticketsMain.adviceTopRightText)}</p></li>
                                <li><h4>{formatMessage(ticketsMain.adviceRightHeader)}</h4> <p>- {formatMessage(ticketsMain.adviceRightText)}</p></li>
                                <li><h4>{formatMessage(ticketsMain.adviceBottomRightHeader)}</h4> <p>- {formatMessage(ticketsMain.adviceBottomRightText)}</p></li>
                                <li><h4>{formatMessage(ticketsMain.adviceBottomMiddleHeader)}</h4> <p>- {formatMessage(ticketsMain.adviceBottomMiddleText)}</p></li>
                                <li><h4>{formatMessage(ticketsMain.adviceBottomLeftHeader)}</h4> <p>- {formatMessage(ticketsMain.adviceBottomLeftText)}</p></li>
                                <li><h4>{formatMessage(ticketsMain.adviceLeftHeader)}</h4> <p>- {formatMessage(ticketsMain.adviceLeftText)}</p></li>
                            </ul>
                            <div className='ticket'>
                                <div className='image-container full-width'>
                                    <Img src={ticket} alt={formatMessage(ticketsMain.ticket)}/>
                                </div>
                            </div>
                            <div className='advice-container advice-top-left'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(ticketsMain.adviceTopLeftHeader)}</h4>
                                    <p>{formatMessage(ticketsMain.adviceTopLeftText)}</p>
                                </div>
                            </div>
                            <div className='line-top-left'>
                                <Img src={lineTopLeft} alt=""/>
                            </div>
                            <div className='advice-container advice-top-right'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(ticketsMain.adviceTopRightHeader)}</h4>
                                    <p>{formatMessage(ticketsMain.adviceTopRightText)}</p>
                                </div>
                            </div>
                            <div className='line-top-right'>
                                <Img src={lineTopRight} alt=""/>
                            </div>
                            <div className='advice-container advice-right'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(ticketsMain.adviceRightHeader)}</h4>
                                    <p>{formatMessage(ticketsMain.adviceRightText)}</p>
                                </div>
                            </div>
                            <div className='line-right'>
                                <Img src={lineRight} alt=""/>
                            </div>
                            <div className='advice-container advice-bottom-right'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(ticketsMain.adviceBottomRightHeader)}</h4>
                                    <p>{formatMessage(ticketsMain.adviceBottomRightText)}</p>
                                </div>
                            </div>
                            <div className='line-bottom-right'>
                                <Img src={lineBottomRight} alt=""/>
                            </div>
                            <div className='advice-container advice-bottom-middle'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(ticketsMain.adviceBottomMiddleHeader)}</h4>
                                    <p>{formatMessage(ticketsMain.adviceBottomMiddleText)}</p>
                                </div>
                            </div>
                            <div className='line-bottom-middle'>
                                <Img src={lineBottomMiddle} alt=""/>
                            </div>
                            <div className='advice-container advice-bottom-left'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(ticketsMain.adviceBottomLeftHeader)}</h4>
                                    <p>{formatMessage(ticketsMain.adviceBottomLeftText)}</p>
                                </div>
                            </div>
                            <div className='line-bottom-left'>
                                <Img src={lineBottomLeft} alt=""/>
                            </div>
                            <div className='advice-container advice-left'>
                                <div className='advice-body'>
                                    <h4>{formatMessage(ticketsMain.adviceLeftHeader)}</h4>
                                    <p>{formatMessage(ticketsMain.adviceLeftText)}</p>
                                </div>
                            </div>
                            <div className='line-left'>
                                <Img src={lineLeft} alt=""/>
                            </div>

                        </div>
                        <div className='row2'>
                            <div className='protip1'>
                                <div className='image-container'>
                                    <Img src={redTicket} alt={formatMessage(ticketsMain.redTicket)}/>
                                </div>
                                <div className='content-container text-left'>
                                    <div className='title'><h3>{formatMessage(ticketsMain.protip1Header)}</h3></div>
                                    <p className='protip-black'>{formatMessage(ticketsMain.protip1Text)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Scrumboard;
